.box-outlined {
  border: 1px solid $secondary;
  border-radius: 8px;
  padding: 15px;
}

.box-app-version {
  font-size: 10px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  background-color: #fff;
  padding: 3px;
  z-index: 9999;
}

// ---------- CARD --------------
.card {
  border: none;
  border-radius: 4px;
}
// ---------- CARD --------------

.overflow-scroll-hidden {
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow-scroll-hidden::-webkit-scrollbar {
  display: none;
}

.section-box {
  border-radius: 4px;
  padding: 10px;
  margin: 2px 5px;
  box-shadow: 0px 3px 10px #bfbfbf29;
}

.outlined-box-primary {
  border: 1px solid $primary;
  border-radius: 4px;
  padding: 15px 25px;
}

button.anchor-like {
  background: transparent;
  border: 0;
  color: $primary;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: darken($primary, 30%);
  }
}

.outlined-box {
  border: 1px solid var(--primary);
  border-radius: 4px;
}

.table-partial-evaluation-grade-entry {
  .table-responsive {
    overflow: inherit;
  }
  tbody tr:nth-last-child(1) td {
    position: relative;
    color: var(--primary);
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--primary);
      opacity: 0.1;
    }
  }
}

.expander-height-input {
  .g-text-outlined-input-container {
    height: 64px;
    input {
      text-align: center;
    }
  }
}

.hiddenColumn {
  opacity: 0;
}

.column-sticky {
  width: 100%;
  position: sticky;
  left: 0px;
  top: 0px;
  thead {
    th {
      z-index: 99;
    }
  }
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    border-right: 1px solid rgba(black, 0.15);
  }
}

.border-input[disabled] {
  border: none;
  background-color: #f4f4f4;
}
.border-input:not([disabled]) {
  border: 1px solid var(--primary);
  border-radius: 4px;
  background-color: transparent;
}

.no-transition {
  transition: none !important;
}

// https://github.com/react-bootstrap/react-bootstrap/issues/3128
.tooltip[data-popper-reference-hidden='true'] {
  visibility: hidden;
  pointer-events: none;
}

.box-ul-detail {
  padding: 8px 48px;
  border: 1px solid var(--primary);
  min-height: 80px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  li p {
    display: flex;
    justify-content: space-between;
  }
}

.g-table-evaluations-ra > .g-table-default > .table-responsive {
  overflow: visible;
}
